.header {
  margin-bottom: 20px;
}

.panel {
  display: flex;
  flex-direction: column;
}

.filters {
  min-width: 300px;
}

.downloadButtom {
  float: right;
  margin-top: 18px;
}

.spacer {
  flex: 1 1 auto;
}

.clearfix {
  clear: both;
}

.message {
  align-self: flex-start;
  width: unset !important;
}
