.form {
  margin-top: 20px;
  margin-bottom: 20px;
}

.form :global(.bp4-label) {
  min-width: 160px;
  width: 170px;
}

.form h4 {
  width: 160px;
  text-align: right;
}

.form :global(.bp4-form-content) {
  flex: 0 0 240px;
}
