.header {
  margin-bottom: 20px;
}

.wrapper {
  display: flex;
  flex-direction: row;
}

.map {
  width: 300px;
  height: 300px;
  z-index: 10;
}

.details {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.detailsRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px !important;
}

.detailsRow :global(.bp4-label) {
  width: 170px;
  text-align: right;
  font-weight: 500;
  margin-right: 10px;
}

.detailsRow :global(.bp4-form-content) {
  width: 250px;
}

.detailsRow :global(.bp4-form-content textarea.bp4-input) {
  min-width: 100%;
}

.buttonGroup {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttonSpacer {
  flex: 1;
}

.kladrButton,
.cancelButton {
  margin-right: 10px;
}

.detailsLabel {
  flex: 1;
  text-align: right;
  font-weight: 500;
  margin-right: 10px;
}

.detailsValue {
  flex: 4;
}

.spacer {
  height: 20px;
}
