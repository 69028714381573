.form :global(.bp4-form-group) {
  display: flex;
  justify-content: space-between;
}

.form :global(.bp4-label) {
  width: 130px;
}

/* stylelint-disable */
:global(.DayPicker-Day--scheduled-date) {
  position: relative;
}

:global(.DayPicker-Day--scheduled-date::after) {
  position: absolute;
  right: 2px;
  bottom: 2px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #137cbd;
  display: block;
  content: " ";
}
