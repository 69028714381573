.gridsWrapper {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  justify-content: space-between;
}

/* Table */
.table {
  margin-top: 5px;
}

.table :global(.bp4-table-column-name-text) {
  pointer-events: unset;
}

.columnHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
}

.columnHeaderText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  will-change: transform;
  position: relative;
  box-sizing: inherit;
  min-width: 49%;
  max-width: 49%;
}

.actionsBar {
  margin-bottom: 20px;
}

.toolbar {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  gap: 5px;
}
