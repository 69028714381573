.header {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: row;
}

.map {
  width: 300px;
  height: 300px;
  z-index: 10;
}

.details {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.detailsRow {
  display: flex;
  flex-direction: row;
}

.detailsLabel {
  flex: 1;
  text-align: right;
  font-weight: 500;
  margin-right: 10px;
}

.detailsValue {
  flex: 4;
  display: inline-flex;
  align-items: flex-end;
}

.history {
  margin-top: 20px;
}

.emptyHistory {
  color: #c0c0c0;
}

.spacer {
  height: 20px;
}

.spinnerIcon {
  display: inline-block !important;
}
