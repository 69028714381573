.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.formRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dateInput :global(.bp4-input-group) {
  width: 500px;
}

.formRow :global(.bp4-label) {
  flex: 1 1 334px;
  margin: 0 !important;
}

.datimeGroupRow .formRow :global(.bp4-label) {
  flex: 1 1 429px;
}

.formRow.disabled {
  opacity: 0.5;
}

.formRow :global(.bp4-form-content) {
  flex: 1 1 500px;
}

.formRow :global(.bp4-popover-wrapper .bp4-popover-target) {
  width: 500px;
}

.formRow :global(.bp4-popover-wrapper > .bp4-popover-target > div > .bp4-button) {
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formRow :global(.bp4-popover-wrapper > .bp4-popover-target > div > .bp4-button > .bp4-button-text) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 457px;
}

.datimeGroupRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.datimeGroupColumn {
  flex: 1 1 auto;
}

.datimeRandom {
  width: 90px;
  margin-left: 20px;
  margin-bottom: 15px;
}

.formRow .datimeWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.formRow .datimeWrapper :global(.bp4-popover-wrapper .bp4-popover-target) {
  width: 248px;
}

.formRow .userWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.formRow .userWrapper :global(.bp4-popover-wrapper .bp4-popover-target) {
  width: 393px;
}

.formRow .userWrapper :global(.bp4-popover-wrapper .bp4-popover-target .bp4-button) {
  width: 393px;
}

.formRow .userWrapper .rowButton {
  width: 90px;
}

.topRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.topRow .topColumn {
  flex: 1 1 auto;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 23px;
}

.shopPhoto {
  width: 130px;
}

.shopPhotoTitle {
  padding: 0 5px;
  text-align: center;
}

.shopPhotoError {
  padding: 0 5px;
  text-align: center;
  color: #c23030;
  font-size: 12px;
}

:global(.bp4-menu-item:not(.bp4-active)) .prioritySelectItem {
  color: #0e5a8a;
}
