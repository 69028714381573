/* ActionBar */
.actionBar {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  min-width: 340px;
  flex-shrink: 0;
}

.openFiltersButton {
  margin-right: 10px;
}

.spacer {
  flex: 1 1 auto;
}

.actionBarErrorMessage {
  color: #f00;
}

/* Filters */
.filtersGroup {
  margin-bottom: 10px;
}

.clearFiltersButton {
  margin-top: 18px;
  float: right;
  width: 103px;
}

.switchFilter {
  float: left;
  margin-top: 25px;
  width: 210px;
}

.clearfix {
  clear: both;
}
