.header {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header > :nth-child(2) {
  margin-left: auto;
  margin-right: 10px;
}

.headerTitle {
  margin-bottom: 0 !important;
}

.details {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.detailsRow {
  display: flex;
  flex-direction: row;
}

.detailsLabel {
  flex: 1 0 50px;
  text-align: right;
  font-weight: 500;
  margin-right: 10px;
}

.detailsValue {
  flex: 4;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cardRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contragentsContainer {
  display: flex;
  flex-flow: row wrap;
}

.contragent {
  min-width: 200px;
  max-width: 400px;
  border: 1px solid #acacac;
  border-radius: 4px;
  padding: 10px;
  margin: 5px;
}

.contragentsTitle {
  margin: 5px;
}

.photo {
  height: 160px;
  width: 160px;
  background-color: #000;
  border-radius: 5px;
  vertical-align: top;
  object-fit: contain;
}
