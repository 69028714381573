.questionaryResult {
  width: 100%;
}

.questionsTable {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #acacac;
  border-radius: 4px;
}

.productTable {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #acacac;
  border-radius: 4px;
}

.productTableLeft {
  width: 270px;
  font-weight: 500;
}

.productGroupPhotoLable {
  margin-left: 5px !important;
  margin-bottom: 5px !important;
}

.productGroupComment {
  margin: 5px 5px 20px;
}

.generalInfoTable {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.generalInfoTable :global(td) {
  box-shadow: none !important;
  border-top: 1px solid #acacac !important;
}

.generalInfoTable .value {
  text-align: right;
}

.irInfoTable {
  margin-bottom: 30px !important;
}

.questionTitle {
  width: 80%;
  height: 40px;
}

.questionText {
  width: 70%;
  height: 40px;
}

.questionResultText {
  width: 20%;
  text-align: right !important;
}

.questionResultTitle {
  text-align: right !important;
}

.questionPhotosTitle {
  text-align: right !important;
}

.resultPhotos {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.resultCardPhotoWrapper {
  position: relative;
}

.resultPhoto {
  margin: 5px;
  height: 150px;
  width: 150px;
  background-color: #000;
  border-radius: 5px;
  vertical-align: top;
  object-fit: contain;
}

.resultCardPhotoWarning {
  position: absolute;
  top: 10px;
  right: 10px;
}

.tabs {
  height: 100%;
}

.tabs > :global(.bp4-tab-panel) {
  width: 100%;
  height: auto;
  overflow-y: auto;
}

.tabs > :global(.bp4-tab-list) {
  height: 100%;
  max-width: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

.tabs > :global(.bp4-tab-list) > :global(.bp4-tab) {
  white-space: unset;
}

.tabs > :global(.bp4-tab-list) :global(.bp4-icon) {
  margin-right: 5px;
}

.tab {
  word-break: break-word;
}

.spinnerIcon {
  display: inline-block !important;
}

.map {
  width: 100%;
  height: 320px;
  z-index: 10;
}

.restoredInfo {
  color: #106ba3 !important;
  font-weight: bold;
}

.irAssortmentSuccess {
  color: #0f9960 !important;
  text-align: center !important;
}

.irAssortmentError {
  color: #c23030 !important;
  text-align: center !important;
}

.irProductHead {
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.equipmentCard {
  margin: 10px 10px 10px 0;
}

.infoRowLabel {
  text-align: right;
  font-weight: bolder;
}

.tdCell {
  word-break: break-word;
}

.jtiTaskLink {
  display: flex;
  align-items: baseline;
}

.jtiTaskText {
  display: flex;
  flex-direction: column;
}

.inspectorCloudTab {
  padding-right: 7px;
}

.irTabHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inspectorCloudSceneCard {
  margin-top: 10px;
}
