.tagInput:global(.bp4-tag-input) > :global(.bp4-tag-input-values) {
  flex-wrap: nowrap;
  overflow: hidden;
}

.selectButton > :global(.bp4-button-text) {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectButtonDanger:global(.bp4-button) {
  border: 1px solid #db3737;
}

.highlight {
  font-weight: 800;
}
