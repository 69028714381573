.interactive {
  display: flex;
}

.actions {
  display: flex;
  flex-direction: column;
}

.clearFiltersButton {
  margin-top: 18px;
  width: 103px;
}

.downloadButton {
  margin-top: 26px;
  width: 103px;
}

.message {
  align-self: flex-start;
  width: unset !important;
}

.extendedQuestionarySelect {
  display: flex;
}
