.content {
  margin-top: 60px;
  min-height: calc(100vh - 140px);
}

.footer {
  border-top: 1px solid #5c708033;
  margin-left: -20px;
  margin-top: 20px;
  padding: 15px 20px;
  text-align: right;
  height: 60px;
}

.copyright,
.version {
  opacity: 0.4;
}

.cookiesNote {
  display: flex;
  flex-direction: column;
}

.acceptCookiesButton {
  align-self: flex-end;
}
