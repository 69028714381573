.active {
  display: block;
}

.inactive {
  display: none;
}

.question {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #acacac;
  border-radius: 4px;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.spacer {
  flex: 1 1 auto;
}

.answerCell {
  text-align: right !important;
  width: 35%;
}

.answerCell :global(.bp4-popover-wrapper .bp4-popover-target) {
  width: 100%;
}

.answerCell :global(.bp4-popover-wrapper > .bp4-popover-target > div > .bp4-button) {
  width: 100%;
  justify-content: space-between;
}

.wordBreak {
  word-break: break-word;
}
