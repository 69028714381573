.navBar:global(.bp4-navbar) {
  /* Чтобы не перекрывала карта */
  z-index: 11;
  height: auto;
}

.navBarGroup {
  justify-content: center;
  flex-wrap: wrap;
  height: auto;
  min-height: 50px;
}

.button {
  margin: 2px;
}

.button:focus {
  outline: 0;
}

.userButton {
  margin: 2px;
}

.userButton:focus {
  outline: 0;
}

.userButton :global(.bp4-button-text) {
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
