.details {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.detailsRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px !important;
}

/* form group */
.detailsRow :global(.bp4-form-content) {
  width: 250px;
}

/* form group label */
.textAreaRow :global(.bp4-label),
.detailsRow :global(.bp4-label) {
  width: 150px;
  font-weight: 500;
  margin-right: 10px;
}

/* simple select */
.detailsRow :global(.bp4-popover-wrapper .bp4-button-text) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 207px;
}

/* textarea */
.textAreaRow .textArea {
  height: 200px;
}

/* simple select */
.detailsRow :global(.bp4-popover-wrapper > .bp4-popover-target > div > .bp4-button) {
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonGroup {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.cancelButton {
  margin-right: 10px;
}

.spacer {
  flex: 1 1 auto;
}
