.filtersGroup {
  margin-bottom: 10px;
  margin-right: 10px;
  width: 210px;
}

.filtersLabel {
  /* from .bp4-text-disabled */
  color: rgb(92 112 128 / 60%);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

:global(.bp4-dark) .filtersLabel {
  /* from .bp4-text-disabled */
  color: rgb(167 182 194 / 60%);
}

/* Daterange Filter */
.filtersDates :global(.bp4-input-group) {
  width: 105px;
}

.filtersNums {
  display: flex;
}

.filtersNumsFrom :global(.bp4-input-group) {
  margin-right: -1px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.filtersNumsTo :global(.bp4-input-group) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

/* Daterange Filter */
.filtersDates :global(.bp4-datepicker-caption select + .bp4-icon) {
  right: 2px !important;
}

/* Combo Filter */
.filtersGroup :global(.bp4-popover-wrapper > .bp4-popover-target > div > .bp4-button) {
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filtersGroup :global(.bp4-popover-wrapper  > .bp4-popover-target > div > .bp4-button > .bp4-button-text) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 167px;
}

.filtersGroup :global(.bp4-tag-input) {
  flex-wrap: nowrap;
  overflow: auto;
  height: auto;
  max-height: 100px;
}