.dateColumn > :global(.bp4-table-column-name) > :global(.bp4-table-column-name-text) {
  padding: 2px;
  text-align: center;
  line-height: 1em;
}

.dateColumn:global(.bp4-table-cell) {
  padding: 0;
  text-align: center;
}

.dateColumnHeaderWeekday {
  font-size: 12px;
  line-height: 12px;
}

.dateColumnIcon {
  vertical-align: middle !important;
  border-radius: 5px;
}

.dateColumnIconInwork {
  /* @orange5 */
  background-color: #ffb366;
}

.dateColumnUnscheduled.dateColumnIconInwork {
  background-color: #48aff0;
}

.dateColumnUnscheduled:global(.bp4-icon.bp4-intent-primary) > svg {
  color: #77450d;
}
