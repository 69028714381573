.loginCard {
  display: flex;
  max-width: 450px;
  min-height: 450px;
  margin: 200px auto;
}

.headerTitle {
  font-weight: normal !important;
}
