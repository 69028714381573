.filtersButton {
  margin-right: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.resultCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.resultCardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #888;
  margin-bottom: 10px;
}

.resultCardPhotos {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  cursor: pointer;
}

.resultCardInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.resultCardInfo > div:first-child {
  flex: 2;
}

.resultCardInfoGroup > p {
  margin: 0;
}

.resultCardPhotoWrapper {
  position: relative;
}

.resultCardPhoto {
  margin: 5px;
  height: 154px;
  width: 154px;
  background-color: #000;
  border-radius: 5px;
}

.resultCardPhotoWarning {
  position: absolute;
  top: 10px;
  right: 10px;
}

.deletePhotoButton {
  margin-top: -26px;
}

.deletePhotoAlertImg {
  margin: 5px;
  width: 300px;
}

.textAlignRight {
  text-align: right;
}

.actionsGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.eventCount {
  color: rgb(92 112 128 / 60%);
}

.spacer {
  flex: 1 1 auto;
}

.restoredInfo {
  color: #106ba3;
  font-weight: bold;
}

.tag {
  margin-bottom: 2px;
}

.showPhotoSelect {
  margin: 0;
}

.actions > .sortSelect {
  margin: 0 0 0 auto;
}

.actions > div:last-child {
  margin-left: 5px;
}

.sortSelect :global(.bp4-button-text) > .sortIcon:global(.bp4-icon) {
  margin: 0;
}

.wordBreak {
  word-break: break-word;
}
