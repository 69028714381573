.container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1;
}

.filters {
  display: flex;
  flex-wrap: wrap;
}

.chartContainer {
  flex-direction: row;
  display: flex;
  min-height: 270px;
  width: 500px;
}

.chart {
  height: 250px;
  width: 250px;
}
