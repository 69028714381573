.uploadPhoto {
  margin: 5px;
  height: 120px !important;
  width: 120px !important;
  background-color: #d8d8d8;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  opacity: 0.8;
  float: left;
}

.uploadPhoto:hover {
  opacity: 1;
}

.uploadPhoto.dragover {
  outline: 3px dashed #d8d8d8;
  cursor: move; /* fallback */
  cursor: grab;
}

.uploadPhoto::before {
  position: absolute;
  width: 60px;
  height: 12px;
  display: block;
  content: "";
  left: 30px;
  top: 54px;
  background-color: #137cbd;
}

.uploadPhoto::after {
  position: absolute;
  width: 12px;
  height: 60px;
  display: block;
  content: "";
  left: 54px;
  top: 30px;
  background-color: #137cbd;
}

.uploadPhoto :global(input) {
  min-width: 120px !important;
  width: 120px !important;
  height: 120px !important;
  cursor: pointer;
}

.uploadPhoto.dragover :global(input) {
  cursor: move; /* fallback */
  cursor: grab;
}

.uploadPhoto:global(.bp4-disabled) {
  opacity: 0.5;
  cursor: default !important;
}

.uploadPhoto:global(.bp4-disabled input) {
  cursor: default !important;
}

.uploadPhoto :global(.bp4-file-upload-input) {
  opacity: 0;
}

.uploadPhoto :global(.bp4-file-upload-input::after) {
  opacity: 0;
}
