.header {
  margin-bottom: 20px;
}

.card {
  display: flex;
  flex-direction: column;
}

.card :global(.bp4-form-group) {
  margin-bottom: 5px;
}

.card :global(.bp4-label) {
  text-align: right;
  font-weight: 500;
  margin-right: 10px;
}

/* form group */
.card :global(.bp4-form-content) {
  width: 250px;
}

.formContainer {
  display: flex;
}

.userForm {
  flex: 1;
}

.userForm :global(.bp4-label) {
  width: 120px;
}

.contragentsContainer {
  display: flex;
  flex-flow: row wrap;
}

.contragentCard {
  margin-top: 10px;
  margin-right: 10px;
}

.contragentForm :global(.bp4-label) {
  width: 95px;
}

.buttonGroup {
  align-self: flex-end;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.buttonGroup > * {
  margin-right: 10px;
}

.buttonGroup > *:last-child {
  margin-right: 0;
}
