.actionBar {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  min-width: 340px;
  flex-shrink: 0;
  align-items: center;
}

.actionBar :global(.bp4-switch) {
  margin-top: 10px;
}

.spacer {
  flex: 1 1 auto;
}

.cogButton {
  margin-right: 10px;
}

.fieldMenuItem {
  padding: 0 !important;
}

.fieldCheckbox {
  margin: 0 !important;
  padding: 5px 5px 5px 31px !important;
}

.openFiltersButton {
  margin-right: 10px;
}

.filtersCard {
  min-width: 340px;
  margin-bottom: 20px;
  padding: 20px 20px 10px !important;
  display: flex;
  justify-content: space-between;
}

.filters {
  display: flex;
  flex-flow: row wrap;
}

.filterButtons {
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
}

.columnHeaderWrapper {
  overflow: visible;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.columnHeaderText {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sortButton {
  pointer-events: all;
  width: 20px;
  height: 20px;
  min-width: 20px !important;
  min-height: 20px !important;
  line-height: 20px;
}

.bottomPager {
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
}
