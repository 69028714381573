.loginCard {
  display: flex;
  max-width: 450px;
  min-height: 450px;
  margin: 200px auto;
}

.loginForm {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.description {
  color: #4d4d4d;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerTitle {
  font-weight: normal !important;
}
