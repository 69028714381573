.switchContainer {
  display: flex;
  flex-direction: row-reverse;
}

.bindingCheckbox {
  display: flex !important;
  padding-top: 4px;
  flex-direction: row;
  justify-content: center;
}

.bindingSpinner {
  padding-top: 4px;
  margin-left: -10px;
}

.toggleAllCheckbox:global(.bp4-control) {
  margin: 0;
}
