.panel {
  display: flex;
  align-items: center;
}

.form {
  flex: 1;
}

.filters {
  min-width: 300px;
  display: flex;
  justify-content: space-between;
}

.buttonsGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.buttonsGroup button {
  margin-top: 10px;
}
