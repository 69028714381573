.error:global(.bp4-menu-item.bp4-disabled) {
  color: #c23030 !important;
}

.select {
  font-size: 14px;
}

.select input {
  font-size: 14px;
}
