.page {
  margin-left: 20px;
  margin-right: 20px;
}

.resultsPanel {
  margin-top: 10px;
}

.pageSelectorBlock {
  height: 50px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.resultCard {
  min-height: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.header {
  margin-bottom: 20px;
}

.resultCardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #888;
  margin-bottom: 10px;
}

.resultCardPhotos {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.resultCardInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.textAlignRight {
  text-align: right;
}

.userLabelRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userLabel {
  overflow: hidden;
  text-overflow: ellipsis;
}

.userTasksLink {
  text-decoration: none !important;
}

.userTasksLink :global(.bp4-tag) {
  display: flex;
  height: 18px;
  min-height: 18px !important;
  line-height: 18px;
}

.clearFiltersButton {
  float: right;
  margin-top: 18px;
}

.filtersPanel {
  margin-bottom: 20px;
  padding: 20px 20px 10px !important;
  min-width: 300px;
}

.switchFilter {
  width: 210px;
  float: left;
  margin-top: 12px;
}

.switchFilter :global(.bp4-control-indicator) {
  top: 10px;
}

.clearfix {
  clear: both;
}

.actionBar {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  flex-shrink: 0;
  width: 100%;
}

.filtersButton {
  margin-right: 10px;
}

.editButton {
  margin-left: auto;
}

.actionBar .actionButtons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
