.container {
  display: flex;
  height: 100%;
  height: calc(100% - 42px);
}

.taskCard {
  flex: 2;
}

.checkCard {
  flex: 1;
  margin-left: 10px;
  overflow: auto;
}

.questionCategory {
  margin-top: 15px;
}

.question {
  margin-top: 5px;
}

.optionFalse:global(.bp4-control) > input:checked ~ :global(.bp4-control-indicator) {
  /* blueprint green4 */
  background-color: #15b371;
}

.optionUndefined:global(.bp4-control) > input:checked ~ :global(.bp4-control-indicator) {
  background-color: gray;
}

.optionTrue:global(.bp4-control) > input:checked ~ :global(.bp4-control-indicator) {
  /* blueprint red4 */
  background-color: #f55656;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
}

.buttonsContainer > button {
  margin-right: 5px;
}

.buttonsContainer > button:last-child {
  margin-right: 0;
}
