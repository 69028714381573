.lightbox-content-center {
  width: 100%;
  height: 100%;
  position: absolute;
}

.lightbox-modifiers-box {
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 1.2em;
  position: absolute;
  width: 100%;
  z-index: 1;
}

@media (min-height: 600px) {
  .lightbox-modifiers-box {
    background-color: transparent;
  }
}

@media (min-width: 768px) {
  .lightbox-image-container {
    margin: 0 5em;
  }
}

.lightbox-image-container {
  overflow: hidden;
  height: calc(100% - 102px);
  margin-top: 40px;
}

.lightbox-image {
  height: 100%;
  background-repeat: no-repeat;
}

.lightbox-image.moving {
  transition: none;
}

.lightbox-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  fill: white;
  animation: rotating 2s linear infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}