.wrapper {
  margin: 5px;
  height: 120px;
  width: 120px;
  border-radius: 5px;
  background-color: #d8d8d8;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  float: left;
}

.resultPhoto {
  height: 120px;
  width: 120px;
  background-color: #000;
  border-radius: 5px;
  cursor: default;
  object-fit: contain;
}

.wrapper .deleteButton {
  position: absolute;
  top: 5px;
  right: 5px;
  border: 1px solid #106ba3;
}

.wrapper .editButton {
  position: absolute;
  top: 5px;
  right: 40px;
  padding: 5px;
  border: 1px solid #106ba3;
}

.wrapper .editButton input {
  display: none;
}

.wrapper .editButton::before {
  margin-right: 0;
}

.wrapper .previewButton {
  position: absolute;
  top: 5px;
  right: 75px;
  padding: 5px;
  border: 1px solid #106ba3;
}
