.detailsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: right;
}

.input {
  width: 250px !important;
}

.dateInput :global(.bp4-input-group) {
  width: 250px;
}

.detailsRow :global(.bp4-popover-wrapper .bp4-button) {
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detailsRow :global(.bp4-popover-wrapper .bp4-button-text) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 207px;
}
