.container {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
