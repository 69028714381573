.header {
  margin-bottom: 20px;
}

.details {
  flex: 3;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.detailsRow {
  display: flex;
  flex-direction: row;
}

.detailsLabel {
  flex: 2;
  text-align: right;
  font-weight: 500;
  margin-right: 10px;
}

.detailsValue {
  flex: 3;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 640px !important;
}

.section {
  margin-bottom: 10px;
}

.selectGroup {
  justify-content: space-between;
}

.buttonGroup {
  text-align: right;
}

.button {
  margin-left: 10px;
}
