.header {
  margin-bottom: 20px;
}

.actionBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}

.filtersGroup {
  margin-bottom: 20px;
}

.filtersLabel {
  color: rgb(92 112 128 / 60%);
}

.filtersDataGroup {
  width: 210px;
  float: left;
  margin-right: 10px;
}

.filtersDataGroup :global(.bp4-popover-wrapper .bp4-button) {
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filtersDataGroup :global(.bp4-popover-wrapper .bp4-button-text) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 207px;
}

.filtersDataGroup :global(.bp4-popover-wrapper .bp4-popover-target) {
  width: 210px;
}

.filtersDataGroup :global(.bp4-form-content) {
  width: 210px;
}

.clearFiltersButton {
  width: 103px;
  margin-top: 18px;
  float: right;
}

.filtersPanel {
  margin: 0;
}

.tradepointInfoContainer {
  margin-top: 20px;
}

.detailsWrapper {
  display: flex;
  flex-direction: row;
}

.details {
  display: flex;
  flex: 2;
  flex-direction: column;
}

.detailsActions {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.detailsRow {
  display: flex;
  flex-direction: row;
}

.detailsLabel {
  flex: 1;
  text-align: right;
  font-weight: 500;
  margin-right: 10px;
}

.detailsValue {
  flex: 2;
}

.detailsLink {
  text-align: right;
  font-weight: 500;
  margin-bottom: 10px;
}

.clearfix {
  clear: both;
}

.allTasksWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.allTasksButton {
  margin-left: 10px;
}

.pageSelectorBlock {
  height: 50px;
  margin: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.resultsPanel {
  display: grid;
  margin-top: 10px;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  grid-gap: 20px;
}

.resultCard {
  display: flex;
  flex-direction: column;
}

.resultCardRow {
  display: flex;
  flex-direction: row;
  min-height: 20px;
  line-height: 20px;
}

.resultCardLabel {
  flex: 1;
  text-align: right;
  margin-right: 10px;
  min-height: 20px;
  line-height: 20px;
  font-weight: 500;
}

.resultCardValue {
  flex: 3;
  min-height: 20px;
  line-height: 20px;
}

.resultCardButtonContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
}

.resultCardTag {
  margin: 0 5px 1px 0;
}
