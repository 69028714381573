/* Event */
.resultCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.resultCardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #888;
  margin-bottom: 10px;
}

.resultCardPhotos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.resultCardInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.resultCardPhoto {
  margin: 5px;
  height: 160px;
  width: 160px;
  background-color: #000;
  border-radius: 5px;
  vertical-align: top;
  object-fit: contain;
}

.textAlignRight {
  text-align: right;
}

.actionsGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.expired {
  font-weight: bold;
  color: #c23030;
}

.statusIcon {
  vertical-align: middle !important;
}
