/* ActionsBar */
.actionsBar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.actionsBar > *:nth-child(n+1) {
  margin-left: 5px;
}

.actionsBar > .pageSelect {
  margin-left: auto;
}

.actionsBar > :global(.bp4-control) {
  margin-bottom: 0;
}

/* Filters */
.filters {
  margin: 10px 0;
}

.filtersCard {
  display: flex;
}

.filtersContainer {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}

.clearFiltersButton {
  margin-left: auto;
  align-self: flex-start;
  margin-top: 17px;
  width: 103px;
}

.switchFilter {
  float: left;
  width: 210px;
  margin-top: 25px;
}

/* Table */
.table {
  margin-top: 5px;
}

.table :global(.bp4-table-column-name-text) {
  pointer-events: unset;
}

.columnHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
}

.columnHeaderText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip {
  display: list-item;
}

.tooltip > span {
  display: block;
}

.toggleAllCheckbox {
  overflow: hidden;
}
