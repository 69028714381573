.header {
  margin-bottom: 20px;
}

.card {
  display: flex;
  flex-direction: column;
}

.textarea {
  min-height: 300px;
}

.textarea:global(.focus-visible::placeholder) {
  opacity: 0 !important;
}

.fileinput {
  width: 350px;
  margin-bottom: 10px;
}

.img {
  display: block;
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
  margin-bottom: 10px;
}
