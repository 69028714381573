.header {
  display: flex;
  justify-content: space-between;
}

.questionaryForm {
  margin-top: 20px;
  margin-bottom: 20px;
}

.questionaryForm :global(.bp4-label) {
  min-width: 160px;
}

.questionaryForm h4 {
  padding-left: 50px;
}

.questionaryForm :global(.bp4-form-content) {
  flex: 0 0 240px;
}

.questionForm :global(.bp4-label) {
  width: 170px;
}

.groupCard {
  margin-bottom: 10px;
}

.groupHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.groupHeader > *:nth-child(n+2) {
  margin-left: 10px;
}

.groupQuestions {
  width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 10px;
}

.actionColumn {
  white-space: nowrap;
}

.textColumn {
  position: relative;
  padding-left: 30px !important;
  word-break: break-word;
  min-width: 120px;
}

.planogramColumn {
  max-width: 220px;
  min-width: 140px;
}

.draggingQuestion {
  opacity: 0;
}

.dragHandle {
  cursor: grab;
  position: absolute;
  left: 6px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.dropTarget {
  box-shadow: 0 0 5px 4px #ad6a00;
}

/* Editor */

.variantsFormItem:global(.bp4-form-group.bp4-inline) {
  flex-direction: column;
}

.variantsFormItem > :global(.bp4-form-content) {
  padding: 1px 1px 0;
  width: 100%;
}

.answerVariant {
  margin-bottom: 3px;
}

.answerVariant:global(.bp4-control-group) > .booleanVariant {
  padding: 5px 5px 0 0;
  flex: 0 0 50px;
  text-align: right;
}

.dependendQuestionsSelect:global(.bp4-fill):global(.bp4-popover-wrapper) {
  width: 50%;
}
