.merchMap {
  width: 1024px;
  height: 445px;
  z-index: 10;
}

.pin {
  position: absolute;
  bottom: 20px;
  left: -6px;
  border-radius: 50%;
  border: 12px solid red;
  width: 12px;
  height: 12px;
  box-shadow: 0 0 0 1px #565656;
}

.pinAfter {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  bottom: -35px;
  left: -9px;
  border: 9px solid transparent;
  border-top: 17px solid black;
}

.pinAfterAfter {
  position: absolute;
  width: 0;
  height: 0;
  top: -18px;
  left: -9px;
  border: 9px solid transparent;
  border-top: 17px solid red;
  background-color: transparent;
}

.bigPinText {
  position: absolute;
  z-index: 20;
  font-weight: 500;
  top: -9px;
  left: -5px;
}

.smallPinText {
  position: absolute;
  z-index: 20;
  font-weight: 500;
  top: -9px;
  left: -3px;
}
