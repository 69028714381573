.bp4-dark {
  color: #f5f8fa;
  color-scheme: none;
}

.bp4-navbar.bp4-dark,
.bp4-dark .bp4-navbar {
  background-color: #394b59;
}

.bp4-dark .bp4-navbar {
  box-shadow: 0 0 0 1px rgb(16 22 26 / 20%), 0 0 0 rgb(16 22 26 / 0%), 0 1px 1px rgb(16 22 26 / 40%);
}

.bp4-dark .bp4-menu {
  background: #30404d;
  color: #f5f8fa;
}

.bp4-popover.bp4-dark .bp4-popover-content,
.bp4-dark .bp4-popover .bp4-popover-content {
  background: #30404d;
}

.bp4-popover.bp4-dark .bp4-popover-arrow-border,
.bp4-dark .bp4-popover .bp4-popover-arrow-border {
  fill: #10161a;
  fill-opacity: 0.2;
}

.bp4-popover.bp4-dark .bp4-popover-arrow-fill,
.bp4-dark .bp4-popover .bp4-popover-arrow-fill {
  fill: #30404d;
}

.bp4-popover.bp4-dark .bp4-popover-arrow::before,
.bp4-dark .bp4-popover .bp4-popover-arrow::before {
  box-shadow: 1px 1px 6px rgb(16 22 26 / 40%);
}

.bp4-dark .bp4-button[class*="bp4-intent-"] {
  box-shadow: 0 0 0 1px rgb(16 22 26 / 40%);
}

.bp4-button.bp4-intent-success {
  background-color: #0f9960;
  background-image: linear-gradient(180deg, hsl(0deg 0% 100% / 10%), hsl(0deg 0% 100% / 0%));
  color: #fff;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-success {
  color: #3dcc91;
}

.bp4-dark .bp4-button-group:not(.bp4-minimal) > .bp4-button:not(:last-child),
.bp4-dark .bp4-button-group:not(.bp4-minimal) > .bp4-popover-wrapper:not(:last-child) .bp4-button {
  margin-right: 1px;
}

.bp4-dark .bp4-button:not([class*="bp4-intent-"]):disabled,
.bp4-dark .bp4-button:not([class*="bp4-intent-"]).bp4-disabled {
  background-color: rgb(57 75 89 / 50%);
  background-image: none;
  box-shadow: none;
  color: rgb(167 182 194 / 60%);
}

.bp4-dark .bp4-button:not([class*="bp4-intent-"]) {
  background-color: #394b59;
  background-image: linear-gradient(180deg, hsl(0deg 0% 100% / 5%), hsl(0deg 0% 100% / 0%));
  box-shadow: 0 0 0 1px rgb(16 22 26 / 40%);
  color: #f5f8fa;
}

.bp4-dark .bp4-button.bp4-minimal {
  background: none;
  box-shadow: none;
  color: inherit;
}

.bp4-button {
  border-radius: 3px;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-success:hover {
  background: rgb(15 153 96 / 20%);
  color: #3dcc91;
}

.bp4-dark .bp4-button[class*="bp4-intent-"]:hover {
  box-shadow: 0 0 0 1px rgb(16 22 26 / 40%);
}

.bp4-button.bp4-intent-success:hover {
  background-color: #0d8050;
}

.bp4-dark .bp4-menu-item.bp4-selected,
.bp4-dark .bp4-menu-item.bp4-selected:hover,
.bp4-dark .bp4-menu-item.bp4-selected:active {
  background-color: #137cbd;
  color: #fff;
}

.bp4-dark .bp4-button:not([class*="bp4-intent-"]):hover {
  background-color: #30404d;
  box-shadow: 0 0 0 1px rgb(16 22 26 / 40%);
}

.bp4-dark .bp4-non-ideal-state-visual {
  color: rgb(167 182 194 / 60%);
}

.bp4-card.bp4-dark,
.bp4-dark .bp4-card {
  background-color: #30404d;
}

.bp4-elevation-2.bp4-dark,
.bp4-dark .bp4-elevation-2 {
  box-shadow: 0 0 0 1px rgb(16 22 26 / 20%), 0 1px 1px rgb(16 22 26 / 40%), 0 2px 6px rgb(16 22 26 / 40%);
}

.bp4-dark .bp4-input {
  background: rgb(16 22 26 / 30%);
  box-shadow:
    0 0 0 0 rgb(19 124 189 / 0%),
    0 0 0 0 rgb(19 124 189 / 0%),
    0 0 0 0 rgb(19 124 189 / 0%),
    inset 0 0 0 1px rgb(16 22 26 / 30%),
    inset 0 1px 1px rgb(16 22 26 / 40%);
  color: #f5f8fa;
}

.bp4-input {
  appearance: none;
  background: #fff;
  border: none;
  border-radius: 3px;
  box-shadow:
    0 0 0 0 rgb(19 124 189 / 0%),
    0 0 0 0 rgb(19 124 189 / 0%),
    inset 0 0 0 1px rgb(16 22 26 / 15%),
    inset 0 1px 1px rgb(16 22 26 / 20%);
  color: #182026;
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  line-height: 30px;
  outline: none;
  padding: 0 10px;
  transition: box-shadow 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
  vertical-align: middle;
}

.bp4-dark .bp4-menu-item.bp4-intent-primary:active,
.bp4-dark .bp4-menu-item.bp4-intent-primary.bp4-active {
  background-color: #137cbd;
  color: #fff;
}

.bp4-dark .bp4-control-group:not(.bp4-vertical) > :not(.bp4-divider) {
  margin-right: 0;
}

.bp4-dark .bp4-datepicker {
  background: #30404d;
}

.bp4-dark .bp4-popover,
.bp4-popover.bp4-dark {
  box-shadow: 0 0 0 1px rgb(16 22 26 / 20%), 0 2px 4px rgb(16 22 26 / 40%), 0 8px 24px rgb(16 22 26 / 40%);
}

.bp4-datepicker {
  border-radius: 3px;
}

.bp4-dark .bp4-divider {
  border-color: rgb(16 22 26 / 40%);
}

.bp4-dark .bp4-input-group .bp4-icon {
  color: #a7b6c2;
}

.bp4-dark .bp4-button.bp4-minimal:hover {
  background: rgb(138 155 168 / 15%);
}

.bp4-dark .bp4-button.bp4-minimal.bp4-active,
.bp4-dark .bp4-button.bp4-minimal:active,
.bp4-dark .bp4-button.bp4-minimal:hover {
  box-shadow: none;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-active,
.bp4-dark .bp4-button.bp4-minimal:active {
  background: rgb(138 155 168 / 30%);
  color: #f5f8fa;
}

.bp4-button.bp4-intent-primary {
  background-color: #137cbd;
  background-image: linear-gradient(180deg, hsl(0deg 0% 100% / 10%), hsl(0deg 0% 100% / 0%));
  box-shadow: inset 0 0 0 1px rgb(16 22 26 / 40%), inset 0 -1px 0 rgb(16 22 26 / 20%);
  color: #fff;
}

.bp4-button.bp4-intent-primary:hover {
  background-color: #106ba3;
}

/* .bp4-dark .bp4-button[class*="bp4-intent-"],
.bp4-dark .bp4-button[class*="bp4-intent-"]:hover {
  box-shadow: 0 0 0 1px rgb(16 22 26 / 40%);
} */

.bp4-dark .bp4-table-container {
  background-color: #30404d;
  box-shadow: 0 0 0 1px rgb(16 22 26 / 40%);
}

.bp4-dark .bp4-table-quadrant {
  background-color: #30404d;
}

.bp4-dark .bp4-table-menu {
  background-color: #30404d;
  box-shadow: 0 1px 0 rgb(16 22 26 / 40%), 1px 0 0 rgb(16 22 26 / 40%);
}

.bp4-dark .bp4-table-column-headers {
  background-color: #30404d;
  color: #f5f8fa;
}

.bp4-dark .bp4-table-row-headers {
  background-color: #30404d;
  color: #a7b6c2;
}

.bp4-dark .bp4-table-cell-client {
  background: #293742;
}

.bp4-dark a,
.bp4-dark a:hover {
  color: #48aff0;
}

.bp4-dark .bp4-dialog,
.bp4-dialog.bp4-dark {
  background: #293742;
  box-shadow: 0 0 0 1px rgb(16 22 26 / 20%), 0 4px 8px rgb(16 22 26 / 40%), 0 18px 46px 6px rgb(16 22 26 / 40%);
  color: #f5f8fa;
}

.bp4-dark .bp4-dialog-header {
  background: #30404d;
  box-shadow: 0 1px 0 rgb(16 22 26 / 40%);
}

.bp4-dialog-header {
  border-radius: 6px 6px 0 0;
}

.bp4-dialog-body {
  flex: 1 1 auto;
  line-height: 18px;
  margin: 20px;
}

.bp4-button.bp4-minimal.bp4-intent-success.bp4-active,
.bp4-button.bp4-minimal.bp4-intent-success:active,
.bp4-button.bp4-minimal.bp4-intent-success:hover {
  background: none;
  box-shadow: none;
  color: #0d8050;
}

.bp4-dark .bp4-control .bp4-control-indicator {
  background-color: #394b59;
  background-image: linear-gradient(180deg, hsl(0deg 0% 100% / 5%), hsl(0deg 0% 100% / 0%));
  box-shadow: 0 0 0 1px rgb(16 22 26 / 40%);
}

.bp4-control.bp4-checkbox .bp4-control-indicator {
  border-radius: 3px;
}

.bp4-control input:checked ~ .bp4-control-indicator {
  background-color: #137cbd;
  background-image: linear-gradient(180deg, hsl(0deg 0% 100% / 10%), hsl(0deg 0% 100% / 0%));
  box-shadow: inset 0 0 0 1px rgb(16 22 26 / 40%), inset 0 -1px 0 rgb(16 22 26 / 20%);
  color: #fff;
}

.bp4-control.bp4-checkbox input:indeterminate ~ .bp4-control-indicator {
  background-color: #137cbd;
  background-image: linear-gradient(180deg, hsl(0deg 0% 100% / 10%), hsl(0deg 0% 100% / 0%));
  box-shadow: inset 0 0 0 1px rgb(16 22 26 / 40%), inset 0 -1px 0 rgb(16 22 26 / 20%);
  color: #fff;
}

.bp4-dark .bp4-control:hover input:checked ~ .bp4-control-indicator {
  background-color: #106ba3;
  box-shadow: 0 0 0 1px rgb(16 22 26 / 40%);
}

.bp4-dark .bp4-control.bp4-checkbox:hover input:indeterminate ~ .bp4-control-indicator {
  background-color: #106ba3;
  box-shadow: 0 0 0 1px rgb(16 22 26 / 40%);
}

.bp4-dark .bp4-form-group.bp4-intent-danger .bp4-form-group-sub-label,
.bp4-dark .bp4-form-group.bp4-intent-danger .bp4-form-helper-text {
  color: #ff7373;
}

.bp4-button.bp4-intent-primary.bp4-disabled,
.bp4-button.bp4-intent-primary:disabled {
  background-color: rgb(19 124 189 / 50%);
  background-image: none;
  border-color: transparent;
  box-shadow: none;
  color: hsl(0deg 0% 100% / 60%);
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-primary {
  color: #48aff0;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-danger {
  color: #ff7373;
}

.bp4-dark .bp4-icon-large.bp4-intent-danger,
.bp4-dark .bp4-icon-standard.bp4-intent-danger,
.bp4-dark .bp4-icon.bp4-intent-danger {
  color: #ff7373;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-danger:hover {
  background: rgb(219 55 55 / 20%);
  color: #ff7373;
}

.bp4-button.bp4-minimal.bp4-intent-danger.bp4-active,
.bp4-button.bp4-minimal.bp4-intent-danger:active,
.bp4-button.bp4-minimal.bp4-intent-danger:hover {
  background: none;
  box-shadow: none;
  color: #c23030;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-primary:hover {
  background: rgb(19 124 189 / 20%);
  color: #48aff0;
}

.bp4-button.bp4-minimal.bp4-intent-primary.bp4-active,
.bp4-button.bp4-minimal.bp4-intent-primary:active,
.bp4-button.bp4-minimal.bp4-intent-primary:hover {
  background: none;
  box-shadow: none;
  color: #106ba3;
}

.bp4-dark .bp4-input:focus {
  box-shadow:
    0 0 0 1px #137cbd,
    0 0 0 1px #137cbd,
    0 0 0 3px rgb(19 124 189 / 30%),
    inset 0 0 0 1px rgb(16 22 26 / 30%),
    inset 0 1px 1px rgb(16 22 26 / 40%);
}

.bp4-dark .bp4-elevation-1,
.bp4-elevation-1.bp4-dark {
  box-shadow: 0 0 0 1px rgb(16 22 26 / 20%), 0 0 0 rgb(16 22 26 / 0%), 0 1px 1px rgb(16 22 26 / 40%);
}

.bp4-dialog-header h6.bp4-heading {
  font-size: 18px;
}

.bp4-callout {
  background-color: rgb(138 155 168 / 15%);
  border-radius: 3px;
  font-size: 14px;
  line-height: 1.5;
  padding: 10px 12px 9px;
  padding-top: 10px;
  padding-right: 12px;
  padding-bottom: 9px;
  position: relative;
  width: 100%;
}

.bp4-callout.bp4-callout-icon > .bp4-icon:first-child {
  color: #5c7080;
  left: 14px;
  position: absolute;
  top: 12px;
}

.bp4-button .bp4-spinner-animation svg {
  width: 16px;
  height: 16px;
}

.bp4-dark .bp4-table-quadrant-stack {
  color-scheme: none;
}

.bp4-dark .bp4-icon-large.bp4-intent-primary,
.bp4-dark .bp4-icon-standard.bp4-intent-primary,
.bp4-dark .bp4-icon.bp4-intent-primary {
  color: #48aff0;
}

.bp4-dark .bp4-tab:not([aria-disabled="true"]):hover,
.bp4-dark .bp4-tab[aria-selected="true"] {
  color: #48aff0;
}

.bp4-tabs.bp4-vertical > .bp4-tab-list .bp4-tab {
  border-radius: 3px;
}

.bp4-dark .bp4-elevation-0,
.bp4-elevation-0.bp4-dark {
  box-shadow: 0 0 0 1px rgb(16 22 26 / 40%), 0 0 0 rgb(16 22 26 / 0%), 0 0 0 rgb(16 22 26 / 0%);
}

.bp4-dark .bp4-input-group.bp4-intent-danger .bp4-input {
  box-shadow:
    0 0 0 0 rgb(219 55 55 / 0%),
    0 0 0 0 rgb(219 55 55 / 0%),
    inset 0 0 0 1px #db3737,
    inset 0 0 0 1px rgb(16 22 26 / 15%),
    inset 0 1px 1px rgb(16 22 26 / 20%);
}

.bp4-dark .bp4-file-upload-input {
  background: rgb(16 22 26 / 30%);
  box-shadow:
    0 0 0 0 rgb(19 124 189 / 0%),
    0 0 0 0 rgb(19 124 189 / 0%),
    0 0 0 0 rgb(19 124 189 / 0%),
    inset 0 0 0 1px rgb(16 22 26 / 30%),
    inset 0 1px 1px rgb(16 22 26 / 40%);
  color: #f5f8fa;
  color: rgb(167 182 194 / 60%);
}

.bp4-file-upload-input {
  border-radius: 3px;
}

.bp4-dark .bp4-file-upload-input::after {
  background-color: #394b59;
  background-image: linear-gradient(180deg, hsl(0deg 0% 100% / 5%), hsl(0deg 0% 100% / 0%));
  box-shadow: 0 0 0 1px rgb(16 22 26 / 40%);
  color: #f5f8fa;
}

.bp4-file-upload-input::after {
  border-radius: 3px;
}

.bp4-dark .bp4-file-upload-input:hover::after {
  background-color: #30404d;
  box-shadow: 0 0 0 1px rgb(16 22 26 / 40%);
}

.bp4-button.bp4-minimal.bp4-intent-success:hover {
  background: rgb(15 153 96 / 15%);
  color: #0d8050;
}

.bp4-dialog {
  background: #ebf1f5;
  border-radius: 6px;
  box-shadow: 0 0 0 1px rgb(16 22 26 / 10%), 0 4px 8px rgb(16 22 26 / 20%), 0 18px 46px 6px rgb(16 22 26 / 20%);
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  padding-bottom: 20px;
  pointer-events: all;
  user-select: text;
  width: 500px;
}

.bp4-button:not([class*="bp4-intent-"]) {
  background-color: #f5f8fa;
  background-image: linear-gradient(180deg, hsl(0deg 0% 100% / 80%), hsl(0deg 0% 100% / 0%));
  box-shadow: inset 0 0 0 1px rgb(16 22 26 / 20%), inset 0 -1px 0 rgb(16 22 26 / 10%);
  color: #182026;
}

.bp4-button.bp4-minimal {
  background: none;
  box-shadow: none;
}

a,
a:hover {
  color: #106ba3;
}

.bp4-overlay-backdrop {
  background: hsl(0deg 0% 100% / 0%);
}

.bp4-context-menu2-virtual-target {
  position: fixed;
}

.bp4-tooltip.bp4-dark .bp4-popover-content,
.bp4-tooltip.bp4-dark .bp4-heading,
.bp4-dark .bp4-tooltip .bp4-popover-content,
.bp4-dark .bp4-tooltip .bp4-heading {
  color: #f5f8fa;
}
