.header {
  margin-bottom: 20px;
}

.panel {
  display: flex;
  flex-direction: column;
}

.filters {
  display: flex;
  flex-wrap: wrap;
}

.downloadButtom {
  margin-top: 8px;
  margin-left: auto;
}

.spacer {
  flex: 1 1 auto;
}

.clearfix {
  clear: both;
}

.message {
  align-self: flex-start;
  width: unset !important;
}

.switchFilter {
  width: 210px;
  float: left;
  margin-top: 22px;
}
