.photoDescriptionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photoDescriptionWrapper {
  display: flex;
  flex-direction: column;
}

.photoDescriptionRow {
  display: flex;
  flex-direction: row;
}

.photoDescriptionLabel {
  text-align: right;
  margin: 0 5px;
  width: 135px;
}

.photoDescriptionValue {
  margin: 0 5px;
  text-align: left;
}
