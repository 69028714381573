.loginCard {
  max-width: 450px;
  min-height: 250px;
  margin: 200px auto;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

.form {
  margin-top: 50px;
  min-height: 120px;
}

.description {
  color: #4d4d4d;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header {
  font-weight: normal !important;
}
