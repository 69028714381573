.inline {
  display: flex;
  flex-flow: wrap;
}

.inline > :global(.bp4-form-group) {
  width: 211px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.inline > :global(.bp4-form-group) > label:global(.bp4-label) {
  margin-bottom: 0;
}

.inline :global(.dateRangeInput) :global(.bp4-input-group) {
  width: 104px;
}

.formGroup > :global(.bp4-label) {
  width: 25%;
  text-align: right;
}

.inline .formGroup > :global(.bp4-label) {
  width: unset;
  text-align: left;
}

.formGroupContent {
  flex: 1;
  overflow: hidden;
  padding: 1px;
}
