.columnHeaderContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.columnHeaderWrapper {
  flex: 1 1 auto;
  overflow: visible;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
}

.columnHeaderText {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.columnHeaderMeasureFix {
  position: absolute;
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  opacity: 0 !important;
}

.sortButton {
  pointer-events: all;
  width: 20px;
  height: 20px;
  min-width: 20px !important;
  min-height: 20px !important;
  line-height: 20px;
}
