.form > :global(.bp4-form-group) {
  justify-content: space-between;
}

.form > :global(.bp4-form-group) > :global(.bp4-label) {
  width: 250px;
}

.detailsRow > label {
  width: 110px;
}

.detailsRow > div {
  width: 50%;
}
