.actionBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.actionButton {
  margin-left: 10px;
}

.spacer {
  flex: 1 1 auto;
}

.taskResultContainer {
  height: calc(100% - 42px);
}
