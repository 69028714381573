.fieldTitle label {
  min-width: 160px;
  display: flex;
}

.fieldTitle > :global(.bp4-form-content) {
  overflow: hidden;
}

.content {
  margin-top: 20px;
}

.pageSelectorBlock {
  height: 50px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.boundQuestionariesTable {
  width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.boundQuestionariesTable th {
  font-weight: normal !important;
  font-size: small;
  color: #818181 !important;
}

.tradepointCard {
  margin-top: 20px;
  padding: 15px !important;
}

.emptyCard {
  margin-top: 10px;
}

.infoRow {
  display: flex;
  flex-direction: row;
  color: #a0a0a0;
}

.tradepointId {
  color: #a0a0a0;
  float: right;
}

.removeQuestionaryButton {
  min-height: 24px !important;
  height: 24px;
}

.questionaryCode {
  opacity: 0.4;
}
