/* stylelint-disable font-family-name-quotes, selector-class-pattern */
@import "@blueprintjs/core/lib/css/blueprint.css";
@import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "@blueprintjs/select/lib/css/blueprint-select.css";
@import "@blueprintjs/table/lib/css/table.css";
@import "bp4-patch.css";

body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.bp4-dark {
  background: #30404d;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}

html {
  /* WTF? */
  margin: 0 calc(100vw - 100%);
}

.lightbox-modifiers-box {
  top: 5px;
}

.lightbox-btn-close {
  top: 5px;
  left: 5px !important;
}

.lightbox-image-container {
  margin-top: 50px !important;
  height: calc(100% - 180px) !important;
}

/* macOS safari workarounds */
.bp4-button {
  margin: 0;
}

.bp4-tag-input .bp4-tag-input-values {
  margin-top: 3px !important;
}

body .bp4-toast-container.bp4-toast-container-bottom {
  position: fixed;
}

.hidden {
  display: none !important;
}

body .leaflet-bottom.leaflet-left,
body .leaflet-bottom.leaflet-right {
  /* display: none !important; */
}

body .leaflet-bottom.leaflet-left,
body .leaflet-bottom.leaflet-right > div > span:first-of-type,
body .leaflet-bottom.leaflet-right > div > a:first-of-type {
  display: none !important;
}
