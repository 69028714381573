.active {
  display: block;
  padding-top: 10px !important;
}

.inactive {
  display: none;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.spacer {
  flex: 1 1 auto;
}

.map {
  width: 100%;
  height: 420px;
  z-index: 10;
  margin-bottom: 20px;
}
